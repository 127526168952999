import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import './index.css';
import App from './App';
import Wine from './components/wine/Wine';
import Shop from './components/tienda/Shop';
import AboutUs from './components/AboutUs';
import Philosophy from './components/Philosophy';
import ShippingForm from './components/tienda/ShippingForm';
import theme from './theme.js';
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/wine" element={<Wine />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/philosophy" element={<Philosophy />} />
          <Route path="/shipping-form" element={<ShippingForm />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
