import React, { useState, useEffect } from 'react';
import { ScrollContainer, ScrollPage } from "react-scroll-motion";
import Navbar from './components/Navbar';
import Video from './components/Video';
import Bottle from './components/Bottle';
import Footer from './components/footer/Footer';
import Title from './components/Title';
import Arrow from './components/Arrow';
import { CheckAgeMainPage } from './components/functions/checkAge';
import PhilosophySection from './components/PhilosophySection'; // Nuevo componente importado
import './App.css';
import ribera from './components/images/ribera.jpg';
import riberaMap from './components/images/ribera.png';
import philosophy from './components/images/philosophy.jpg';
import Restaurants from "./components/Restaurants";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";

function App() {
  const [scroll, setScroll] = useState(0);
  const [scrollValue, setScrollValue] = useState(375);

  useEffect(() => {
    const root = document.documentElement;
    const computedStyle = getComputedStyle(root);
    const value = parseInt(computedStyle.getPropertyValue('--video-scroll-value'), 10);
    setScrollValue(value);

    const handleScroll = () => {
      setScroll(window.scrollY);
      document.body.style.setProperty(
        "--scroll",
        window.scrollY / (document.body.offsetHeight - window.innerHeight)
      );
    };

    window.addEventListener('scroll', handleScroll);

    // Limpia el evento de scroll al desmontar el componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="general">
      <ScrollContainer>
        <ScrollPage>
          <div className="logo-container">
            <div className="logo-animator">
                <Title />
            </div>
            <div className="video-animator">
                <div className={`header ${scroll > scrollValue ? 'video-inactive' : 'video-fixed'}`}>
                <Video />
              </div>
            </div>
          </div>
            <Arrow />
        </ScrollPage>

        <ScrollPage>
          <PhilosophySection
            title="Nuestra Filosofía"
            subtitle="El corazón de ANIMAM VIENTEM está centrado en elaborar un vino de máxima calidad que refleje nuestra pasión, un proyecto único, donde cada botella refleje nuestro esfuerzo conjunto, nuestra amistad y nuestro amor por la tradición y el buen vino, características fundamentales que representan lo mejor de nuestra tierra."
            alignment="left"
            img={ribera}
            button="Conócenos"
            link="aboutUs"
          />
        </ScrollPage>

        <ScrollPage>
          <PhilosophySection
            title="Pasión por la tierra"
            subtitle="En Tórtoles de Esgueva, ubicado en la zona más septentrional de la Ribera del Duero Burgalesa, nos encontramos con un terreno excepcional para el cultivo de la vid. El lugar ideal donde crear nuestro vino ANIMAM VIVENTEM, un vino con alma viva."
            alignment="right"
            img={philosophy}
            button="Descubre más sobre nuestro vino"
            link="wine"
          />
        </ScrollPage>

        <Box className="riberaMapImageContainer">
          <img src={riberaMap} className="riberaMapImage"/>
        </Box>

        <ScrollPage className="lawyer" style={{ minHeight: '100vh' }}>
            <Bottle />
        </ScrollPage>

        <ScrollPage className="lawyer" style={{ minHeight: '100vh' }}>
            <Restaurants />
        </ScrollPage>
      </ScrollContainer>

      <Navbar />
      <Footer />
      <CheckAgeMainPage/>
    </div>
  );
}

export default App;
