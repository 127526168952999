import React from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import WineBarIcon from '@mui/icons-material/WineBar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SmellIcon from '@mui/icons-material/Flare'; // No hay icono directo de nariz en MUI, este es un ejemplo
import LipsIcon from '@mui/icons-material/Face'; // Ejemplo para boca

const WineInfo = () => {
  return (
    <Grid container spacing={2} sx={{ margin: {lg: '20px'} }}>
      {/* Localización */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <PlaceIcon /> Tórtoles de Esgueva (Burgos) – Ribera del Duero
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />

      {/* Uva */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <LocalBarIcon /> Tempranillo 100%
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />

      {/* Barrica */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <WineBarIcon /> 13 – 15 meses en barrica de roble (95% francés; 5% americano)
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />

      {/* Color */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <VisibilityIcon /> Cereza picota, limpio y brillante.
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />

      {/* Aroma */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <SmellIcon /> Nariz compleja con intenso aroma a fruta roja madura (fresas, frambuesas...) que le otorga frescura y un fondo ligero de tostados, especias y toffee.
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />

      {/* Boca */}
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom sx={{fontFamily: "'Marcellus', serif"}}>
          <LipsIcon /> Entrada suave y fresca, fácil de beber. Taninos sedosos de carácter frutal. Destaca la persistencia final, manteniendo la fruta durante un tiempo prolongado.
        </Typography>
      </Grid>
      <Divider style={{ width: '100%', backgroundColor: 'white' }} />
    </Grid>
  );
};

export default WineInfo;
