import React from 'react';
import { Card, CardMedia, CardContent, Typography, CardHeader, IconButton } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import bottle from './images/bottle.jpg';
import { useNavigate } from 'react-router-dom'; // Para redirigir al componente Shop

const Bottle = () => {
  const navigate = useNavigate(); // Hook para la navegación

  const handleCardClick = () => {
    navigate('/shop'); // Redirige al componente Wine
  };

  const handleCartClick = () => {
    navigate('/shop'); // Redirige al componente Shop
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
      <Card
        sx={{
          maxWidth: 500,
          width: '100%',
          boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
          borderRadius: '16px',
          overflow: 'hidden',
          position: 'relative',
          transition: 'transform 0.3s ease-in-out',
          '&:hover': {
            transform: 'scale(1.05)',
            cursor: 'pointer', // Mostrar un puntero para indicar que es clickable
          }
        }}
         // Evento para hacer clic y redirigir a Wine
      >
        <CardHeader
          onClick={handleCardClick}
          title={
            <Typography
              variant="h4"
              sx={{
                fontFamily: 'Marcellus, serif',
                color: '#3f3d56',
                textAlign: 'center'
              }}
            >
              Tienda online
            </Typography>
          }
        />
        <CardMedia
          onClick={handleCardClick}
          component="img"
          image={bottle}
          alt="Nuestra Botella"
          sx={{ height: 400, objectFit: 'cover' }}
        />
          <CardContent
              sx={{
                  paddingLeft: '3vw',
                  paddingRight: '0',
                  display: 'flex',
                  gap: '2vw'
              }}>
              <div className="spacer"></div>
              <Typography
                  variant="h6"
                  sx={{
                      fontFamily: 'Marcellus, serif',
                      color: '#5c0c0c',
                      textAlign: 'start',
                      letterSpacing: '0',
                      maxWidth: 'max-content'
                  }}
              >
                  El vino de ANIMAM VIVENTEM
              </Typography>
              {/* IconButton con redirección a Shop */}
              <IconButton
                  className="spacer"
                  sx={{
                      color: '#5c0c0c',
                      padding: '0'
                  }}
                  aria-label="add to cart"
                  onClick={handleCartClick} // Redirige a Shop
              >
                  <ShoppingCartIcon/>
              </IconButton>
          </CardContent>

      </Card>
    </div>
  );
};

export default Bottle;
