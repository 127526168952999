import React from 'react';
import './PrivacyPolicy.css';
import Box from "@mui/material/Box";
import NavbarB from "../NavbarB";
import Footer from "../footer/Footer";
import headerIMG from "../images/header-wine.jpg";
import {Typography} from "@mui/material";
import Fines from "./Fines";

const PrivacyPolicy = () => {
  return (
    <div>
        <Box className="privacyPolicy" sx={{marginBottom: '10hv', bgcolor: '#5c5c5c', minHeight: '100vh'}}>
            <Box
                className="privacy-policy-title"
                sx={{
                    background: `url(${headerIMG}) center/cover no-repeat`,
                    height: '40vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {/* Título en dorado encima de las Cards */}
                <Typography
                    variant="h1"
                    sx={{
                        color: '#7c1d12',
                        fontFamily: "'Marcellus', serif",
                        
                        textShadow: '2px 2px 8px rgba(0, 0, 0, 0.4)',
                    }}
                >
                    Política de Privacidad
                </Typography>
            </Box>

            <p className="privacy-policy-subtitle">
                1. ¿Por qué es necesaria la Política de privacidad?
            </p>
              <h6 className="privacy-policy-paragraph">
                El Reglamento general de protección de datos 1 (“RGPD”) y la Ley Orgánica 3/2018, de 5
                de diciembre, de Protección de Datos Personales y garantía de los derechos digitales
                (“LOPDGDD”) exigen, en virtud del principio de transparencia, que los interesados
                conozcan las circunstancias y condiciones del tratamiento de sus datos personales.
              </h6>
            <p className="privacy-policy-subtitle">
                2. ¿Quién es el responsable del tratamiento de tus datos?
            </p>
              <h6 className="privacy-policy-paragraph">
                El responsable del tratamiento de tus datos es BODEGAS ANIMAM VIVENTEM, S.L. (en
                adelante, la “Empresa”), con N.I.F. B13646336, cuyos datos de contacto son los
                siguientes:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>
                        Domicilio: Calle Fuentespinos, 7, 47317, San Llorente, Valladolid (España)
                    </li>
                    <li>
                        Correo electrónico: bodegas.animam.viventem@gmail.com
                    </li>
                </ul>
              </h6>
            <p className="privacy-policy-subtitle">
                3. ¿Cómo hemos obtenido tus datos?
            </p>
              <h6 className="privacy-policy-paragraph">
                Con carácter general, la Empresa obtiene los datos personales de los propios interesados.
                No obstante, en algunos supuestos, los datos personales tratados proceden de terceros.
                En este último supuesto, se informará al interesado de acuerdo con el artículo 14 RGPD.
              </h6>
            <p className="privacy-policy-subtitle">
                5. ¿Con qué finalidad tratamos tus datos personales?
            </p>
              <h6 className="privacy-policy-paragraph">
                Dependiendo de tu relación con la Empresa, trataremos tus datos personales para las siguientes
                finalidades:
              </h6>
            <Fines/>
              <h6 className="privacy-policy-paragraph">
                No se ejecutarán decisiones exclusivamente automatizadas con los datos personales obtenidos.
              </h6>
            <p className="privacy-policy-subtitle">
                7. ¿Cuánto tiempo conservaremos tus datos?
            </p>
              <h6 className="privacy-policy-paragraph">
                Los datos personales proporcionados se conservarán y tratarán en la medida que los necesitemos a fin de
                poder utilizarlos según la finalidad por la que fueron recabados y según la base jurídica del
                tratamiento de los mismos de acuerdo con la ley aplicable.
              </h6>
              <h6 className="privacy-policy-paragraph">
                En algunos casos, sus datos personales podrán ser mantenidos mientras exista una relación o mientras no
                ejerza su derecho de supresión y/o limitación del tratamiento de sus datos.
              </h6>

            <p className="privacy-policy-subtitle">
                8. ¿Cedemos tus datos a terceros?
            </p>
              <h6 className="privacy-policy-paragraph">
                Para cumplir las finalidades indicadas en la presente Política de Privacidad es necesario que demos
                acceso a tus datos personales a terceras partes, por ejemplo:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>Entidades financieras, administración tributaria, Cuerpos y fuerzas de seguridad del estado,
                        entre otros.
                    </li>
                    <li>Proveedores de servicios tecnológicos, gestorías fiscales y/o laborales.</li>
                </ul>
              </h6>

              <h6 className="privacy-policy-paragraph">
                Por eficiencia del servicio, algunos de los prestadores mencionados pueden estar ubicados en territorios
                situados fuera del Espacio Económico Europeo que no proporcionan un nivel de protección de datos
                equiparable al de la Unión Europea.
              </h6>
              <h6 className="privacy-policy-paragraph">
                En tales casos, te informamos de que transferimos tus datos con garantías adecuadas y siempre guardando
                la seguridad de tus datos:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>Algunos proveedores de Estados Unidos están adheridos al Data Privacy Framework: <a
                        href="https://www.dataprivacyframework.gov/list" target="_blank"
                        rel="noopener noreferrer">https://www.dataprivacyframework.gov/list</a></li>
                    <li>Con otros proveedores situados fuera de la UE se firman Cláusulas Contractuales Tipo aprobadas
                        por la Comisión, cuyo contenido puedes consultar en el siguiente enlace: <a
                            href="https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_en"
                            target="_blank"
                            rel="noopener noreferrer">https://commission.europa.eu/publications/standard-contractual-clauses-controllers-and-processors-eueea_en</a>
                    </li>
                </ul>
              </h6>
              <h6 className="privacy-policy-paragraph">
                En todo caso, la transferencia solo será posible si no prevalecen los derechos, libertades e intereses
                de los interesados.
              </h6>
            <p className="privacy-policy-subtitle">
                9. ¿Cuáles son tus Derechos?
            </p>
              <h6 className="privacy-policy-paragraph">
                El interesado podrá ejercer los siguientes derechos en relación al tratamiento de sus datos, de forma
                gratuita:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>Derecho a solicitar el acceso a sus datos personales.</li>
                    <li>Derecho a solicitar su rectificación o supresión cuando, entre otros motivos, los datos ya no
                        sean necesarios para la ejecución del contrato.
                    </li>
                    <li>Derecho a solicitar la limitación de su tratamiento, en cuyo caso únicamente los conservaremos
                        para el ejercicio o defensa de reclamaciones o si pudiera derivarse algún tipo de
                        responsabilidad judicial, legal o contractual de su tratamiento.
                    </li>
                </ul>
              </h6>

              <h6 className="privacy-policy-paragraph">
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>Derecho a oponerse al tratamiento, en cuyo caso la Empresa dejará de tratar los datos, salvo por
                        motivos legítimos, imperiosos, o el ejercicio o defensa de posibles reclamaciones, y los
                        mantendremos debidamente bloqueados durante el plazo correspondiente mientras persistan las
                        obligaciones legales.
                    </li>
                    <li>Derecho a la portabilidad de los datos. Las personas interesadas pueden solicitar y recibir los
                        datos que les incumban y/o que nos haya facilitado o solicitar que se los enviemos a otro
                        responsable del tratamiento de su elección, en un formato estructurado de uso común y lectura
                        mecánica.
                    </li>
                    <li>Derecho a retirar el consentimiento prestado, en cualquier momento, sin que ello afecte a la
                        licitud del tratamiento basado en el consentimiento previo a su retirada.
                    </li>
                </ul>
              </h6>

              <h6 className="privacy-policy-paragraph">
                Para el ejercicio de sus derechos, el interesado podrá enviar remitir un mensaje, acompañando fotocopia
                del DNI o documento equivalente, señalando el derecho que se ejerce, a la siguiente dirección:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>Correo electrónico: bodegas.animam.viventem@gmail.com</li>
                    <li>Dirección postal: Bodegas ANIMAM VIVENTEM S.L., Calle Fuentespinos, 7, 47317 San Llorente,
                        Valladolid, España
                    </li>
                </ul>
              </h6>
              <h6 className="privacy-policy-paragraph">
                La Empresa atenderá sus peticiones a la mayor brevedad posible y, en todo caso, en el plazo de un mes
                desde la recepción de su solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario,
                teniendo en cuenta la complejidad y el número de solicitudes. La Empresa informará al interesado de la
                prórroga dentro del primer mes desde la solicitud.
              </h6>
              <h6 className="privacy-policy-paragraph">
                Si el interesado no hubiera obtenido satisfacción en el ejercicio de sus derechos puede contactar
                presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente, sin
                perjuicio de las acciones judiciales que considere. A estos efectos, los datos de contacto de la Agencia
                Española de Protección de Datos son los siguientes:
                <ul style={{listStyleType: 'disc', paddingLeft: '20px'}}>
                    <li>
                        Sede electrónica: <a href="https://sedeagpd.gob.es/sede-electronica-web/" target="_blank"
                                             rel="noopener noreferrer">https://sedeagpd.gob.es/sede-electronica-web/</a>
                    </li>
                    <li>
                        Dirección postal: Agencia Española de Protección de Datos, C/ Jorge Juan, 6, 28001 Madrid –
                        España
                    </li>
                    <li>
                        Información general: <a href="http://www.agpd.es/" target="_blank"
                                                rel="noopener noreferrer">http://www.agpd.es/</a>
                    </li>
                </ul>
              </h6>
            <p className="privacy-policy-subtitle">
                10. ¿Adoptamos medidas de seguridad?
            </p>
              <h6 className="privacy-policy-paragraph">
                Sí, la Empresa adopta y actualiza sus medidas de seguridad organizativas y técnicas con el fin de que el
                tratamiento de datos personales que realiza sea conforme con los requisitos de la legislación vigente en
                materia de protección de datos nacional y europea.
              </h6>

            <p className="privacy-policy-subtitle">
                11. ¿Se puede modificar la Política de privacidad?
            </p>
              <h6 className="privacy-policy-paragraph">
                Sí, la Empresa se reserva el derecho de modificar su Política de privacidad, cumpliendo con la
                legislación aplicable en cada momento. Cualquier modificación de dicha Política será publicada en la
                página web, por lo que le sugerimos que consulte los términos de la misma con relativa frecuencia.
                Última actualización: 19 de agosto de 2024.
              </h6>
        </Box>
        <NavbarB/>
        <Footer/>
    </div>
  );
};

export default PrivacyPolicy;
