// Popup.js
import React from 'react';
import { Dialog, DialogContent, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';

function PopUpPayment({ result, orderNumber }) {
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up('lg'));

  // Definir el mensaje basado en los props
  let message;
  if (result === 'success') {
    message = `La operación se ha realizado de forma correcta, su número de pedido es: ${orderNumber}`;
  } else if (result === 'failure') {
    message = `Ha ocurrido un error al procesar el pago, contacte con su entidad bancaria. Su número de pedido es: ${orderNumber}`;
  }

  // Función para cerrar el popup y recargar sin query params
  const handleClose = () => {
    window.location.href = `${window.location.origin}${window.location.pathname}`;
  };

  return (
    <Dialog
      open={Boolean(message)}
      maxWidth="lg"
      PaperProps={{
        style: {
          width: isLgUp ? '40vw' : '80vw',
          backgroundColor: '#eee',
        },
      }}
    >
      <DialogContent>
        <Typography variant="h6" align="center">
          {message || 'Información no disponible'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClose}
          style={{ display: 'block', margin: '0 auto' }}
        >
          Cerrar
        </Button>
      </DialogContent>
    </Dialog>
  );
}

export default PopUpPayment;
