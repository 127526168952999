import React from 'react'
import './Footer.css'
import {Typography} from "@mui/material";
import footerIMG from "../images/kitDigital.png";
import Base from "./Base";

const Footer = () => {
    return (
        <div className='footer'>
            <Typography
              className="footerTitle"
              variant="h1"
              sx={{
                color: '#b68d2c',
                fontFamily: "'Marcellus', serif",
                fontSize: 'var(--font-size-h1)',
                textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
                letterSpacing: '4px',
              }}
            >
              Animam Viventem
            </Typography>
            <Base></Base>
            <img className='footerIMG' src={footerIMG} alt={"Programa Jit Digital cofinanciado por los fondos Next Generation (EU) del mecanismo de recuperación y resiliencia"}/>

        </div>
    )
}

export default Footer
