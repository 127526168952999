import React from 'react';
import {Grid, Typography, Button, useMediaQuery} from '@mui/material';
import { Link } from 'react-router-dom'; // Importa Link
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import './Base.css';
import logo from "../images/logo.png"
import theme from "../../theme";

const Base = () => {
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div className="base-container">
      <Grid container
            spacing={6}
            direction="column"
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
      >
        <Grid item>
              <img src={logo} alt="Aniamam Logo" className="base-logo" />
        </Grid>
        <Grid item container spacing={4}
          sx={ {
            alignItems: 'center',
            justifyContent: {xs:'start', lg: 'center'}
          }}
        >
          {/* Columna Izquierda */}
          <Grid item direction="column" className="base-social">
            <Grid item className="base-social">
              <Typography variant="h6" className="base-social">Contáctanos en:</Typography>
            </Grid>
            <Grid item className="base-social">
              <Grid item className="base-socials"><EmailIcon /></Grid>
              <Typography variant="h6" className="base-social">
                <a href="mailto:bodegas.animam.viventem@gmail.com">
                  {isLargeScreen ? 'bodegas.animam.viventem@gmail.com' : 'mail'}
                </a>
              </Typography>
            </Grid>
            <Grid item className="base-social">
              <Typography variant="h6" className="base-social">Visítanos en:</Typography>
            </Grid>
            <Grid item direction="row" className="base-social">
              <Grid item className="base-socials"><InstagramIcon /></Grid>
              <Grid item className="base-social">
                <Typography variant="h6" className="base-social">
                  <a href="https://instagram.com/bodegas_animam_viventem" target="_blank" rel="noopener noreferrer">
                    {isLargeScreen ? 'bodegas_animam_viventem' : 'instagram'}
                  </a>
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Columna Derecha */}
          <Grid item direction="column" className="base-social">
            <Grid item className="base-social">
              <Typography variant="h6" className="base-social">
                <a href="/">Inicio</a>
              </Typography>
            </Grid>
            <Grid item className="base-social">
              <Typography variant="h6" className="base-social">
                <a href="/aboutUs">El corazón de ANIMAM VIVENTEM</a>
              </Typography>
            </Grid>
            <Grid item className="base-social">
              <Typography variant="h6" className="base-social">
                <a href="/wine">El Vino de ANIMAM VIVENTEM</a>
              </Typography>
            </Grid>

            <Link to="/shop">
              <Button variant="outlined" className="base-button">TIENDA ONLINE</Button>
            </Link>
          </Grid>
        </Grid>
        {/* Parte Inferior */}
        <Grid item>
          <Typography variant="body2" className="base-bottom">
            <a href="/privacy-policy"> Política de privacidad – Condiciones Generales de venta </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default Base;
