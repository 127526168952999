import React from 'react';
import './PhilosophySection.css';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import {Typography} from "@mui/material";
import Link from "@mui/material/Link";

const PhilosophySection = ({ title, subtitle, alignment, img, button, link }) => {
  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: 'rgba(92,12,12,0.62)',
            dark: '#000000',
          },
        },
      }}
    >
      <Box
        className="philosophy-section"
        display="flex"
        flexDirection={alignment === 'right' ? 'row-reverse' : 'row'}
        alignItems="center"
      >
        {img && (
          <Box className="philosophy-image-container" flex={1}>
            <img src={img} alt="Philosophy" className="philosophy-image" />
          </Box>
        )}
        <Box
          className="letras-container"
          sx={{
            left: {
              xs: '0',
              lg: alignment === 'right' ? '-20%' : '50%',
            },
            width: {
              xs: 'auto',
              lg: '50vw'
            }
          }}
        >
          <h1 className="philosophy-title">{title}</h1>
          {subtitle && (
          <Typography className="philosophy-description" variant="h6"
            sx={{
              fontFamily: "'Marcellus', serif",
              mx: 2
            }}
          >
            {subtitle}
            {button && (
                <a
                  href={`/${link}`}
                  style={{
                    color: 'black',
                    textDecoration: 'underline',
                    marginLeft: '8px'
                  }}
                >
                  {button}
                </a>
            )}
          </Typography>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PhilosophySection;
