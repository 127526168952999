import React, { useState, useEffect } from 'react';
import { Box, Grid, Card, CardContent, Typography, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import headerIMG from '../images/header-wine.jpg';
import NavbarB from '../NavbarB';
import "../common.css"
import "./shop.css"
import Footer from "../footer/Footer";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useLocation, useNavigate} from "react-router-dom";
import {ShopHeader} from "./ShopHeader";
import PopUpPayment from "./PopUpPayment";

const Shop = () => {
  // Estados para las cantidades de cada card
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const result = queryParams.get('result');
  const orderNumber = queryParams.get('orderNumber');

  useEffect(() => {
    // Obtener productos del backend
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.bodegasanimamviventem.es/api/products/', {
          method: 'GET',
        });
        if (!response.ok) {
          throw new Error('Error al obtener los productos');
        }
        const data = await response.json();
        setProducts(data);
        const initialQuantities = {};
        data.forEach(product => {
          initialQuantities[product.product_id] = 0; // Inicializar cantidades a 0
        });
        setQuantities(initialQuantities);
      } catch (error) {
        console.error('Error al obtener los productos:', error);
      }
    };

    fetchProducts();
  }, []);

  const handleIncrease = (productId) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: prevQuantities[productId] + 1,
    }));
  };

  const handleDecrease = (productId) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [productId]: Math.max(prevQuantities[productId] - 1, 0),
    }));
  };

  const initiatePayment = async () => {
    const selectedProducts = products.map(product => ({
      product_id: product.product_id,
      quantity: quantities[product.product_id],
      name: product.name,
      price: product.price
    })).filter(product => product.quantity > 0);
    navigate('/shipping-form', { state: { products: selectedProducts } });
  };

  return (
    <div>
    <Box sx={{ bgcolor: '#5c5c5c', minHeight: '100vh', position: {xs: 'relative', lg: 'static'}, top: {xs: '90px'} }}>
      <ShopHeader/>

      {/* Conjunto de Cards */}
      <Grid container spacing={4} justifyContent="center" sx={{ marginTop: 6 }}>
        {/* Card 1: Una botella */}
        {products.map((product) => (
        <Grid item xs={12} md={4}>
          <Card
            sx={{
              padding: 4,
              textAlign: 'center',
              boxShadow: '0 8px 16px rgba(0, 0, 0, 0.3)',
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
              bgcolor: "#EEEEEEFF"
            }}
          >
            <Typography variant="h6" gutterBottom
              sx={{
                fontFamily: "'Marcellus', serif",
                marginBottom: 2
              }}
            >
              {product.name}
            </Typography>
            <Typography variant="h6" gutterBottom
                        sx={{
                          fontFamily: "'Marcellus', serif",
                          marginBottom: 2,
                          color: "#b68d2c"
                        }}
            >
              ANIMAM VIVENTEM <div className="bottleYearTitle">2020</div>
            </Typography>
            <Typography variant="h6" gutterBottom
              sx={{
                fontFamily: "'Marcellus', serif",
                marginBottom: 2
              }}
            >
              {product.price.toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} €
            </Typography>
            <Box
              component="img"
              src={`/${product.img}.png`}
              alt={product.name}
              sx={{
                width: 'auto',
                height: '300px',
                margin: '0 auto',
                marginBottom: 2,
                borderRadius: 4,
                transition: 'transform 0.3s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
            />
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <IconButton
                  onClick={() => handleDecrease(product.product_id)}
                  sx={{ color: 'darkred' }}
                >
                  <RemoveIcon />
                </IconButton>
                <Typography variant="h6"
                  sx={{
                    fontFamily: "'Marcellus', serif",
                    mx: 2
                  }}
                >
                  {quantities[product.product_id]}
                </Typography>
                <IconButton
                  onClick={() => handleIncrease(product.product_id)}
                  sx={{ color: 'darkgreen' }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                  <div className="prices">
                      {(product.price * quantities[product.product_id]).toLocaleString('es-ES', { minimumFractionDigits: 0, maximumFractionDigits: 2 })} €
                  </div>
                  <IconButton
                      sx={{
                        color: '#7c1d12'
                      }}
                      aria-label="add to cart"
                      onClick={initiatePayment}
                    >
                        <ShoppingCartIcon />
                  </IconButton>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        ))}
      </Grid>
    </Box>
    <NavbarB/>
    <Footer />
    {result && orderNumber && (
      <PopUpPayment result={result} orderNumber={orderNumber} />
    )}
    </div>
  );
};

export default Shop;
