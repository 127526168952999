import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CookieNotice = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <span style={styles.text}>
          <IconButton style={styles.closeButton} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
          Esta página utiliza cookies esenciales para su correcto funcionamiento.
        </span>
      </div>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#eeeeee',
    zIndex: 1000,
    maxWidth: '100vw'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    padding: '0 16px',
    maxWidth: '100%',
  },
  closeButton: {
    position: 'absolute',
    left: '85vw',
    top: '8px',
  },
  text: {
    fontSize: '14px',
    color: '#333',
  },
};

export default CookieNotice;
