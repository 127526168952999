import {Box, Typography} from "@mui/material";
import headerIMG from "../images/header-wine.jpg";
import React from "react";


export const ShopHeader = () => {
    return(
      <Box
        sx={{
          background: `url(${headerIMG}) center/cover no-repeat`,
          height: '40vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#b68d2c',
            fontFamily: "'Marcellus', serif",
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.4)',
            fontSize: 'var(--font-size-h1)',
            textAlign: 'center'
          }}
        >
          Animam Viventem
        </Typography>
      </Box>
)
}