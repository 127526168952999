import React from 'react';
import {Card, CardMedia, CardContent, Typography, CardHeader, IconButton, css} from '@mui/material';
import img1 from "./images/raza.svg"
import img2 from "./images/flamma.svg"
import img3 from "./images/curioso.png"
import img4 from "./images/elbar.png"
import img5 from "./images/villaParamesa.png"
import { Carousel } from "primereact/carousel";
import "./Restaurants.css"

const Restaurants = () => {
  const responsiveOptions = [
    {
      breakpoint: '1024px', // Para pantallas de más de 1024px
      numVisible: 3,        // Muestra 3 items visibles
      numScroll: 3          // Desplaza 3 items
    },
    {
      breakpoint: '768px',  // Para pantallas de entre 768px y 1024px
      numVisible: 2,        // Muestra 2 items visibles
      numScroll: 2          // Desplaza 2 items
    },
    {
      breakpoint: '0',  // Para pantallas de menos de 768px
      numVisible: 1,        // Muestra solo 1 item visible
      numScroll: 1          // Desplaza 1 item
    }
  ];
  function getPicture(picture) {
    return (
        <div className="pictures">
            <img src={picture} className="picture" alt="restaurant" />
        </div>
      )
  }

  return (
    <div style={{ display: 'flex', flexDirection:"column", justifyContent: 'center', alignItems: 'center', height: '100vh' }}>

      <Typography variant="h4" gutterBottom sx={{ fontFamily: "'Marcellus', serif", marginBottom: '15vh' }}>
        Dónde encontrarnos:
      </Typography>

      <Carousel value={[img1, img2, img3, img4, img5]} numVisible={3} circular={true} numScroll={1} className="custom-carousel" circular responsiveOptions={responsiveOptions} autoplayInterval={1500} itemTemplate={getPicture} />
    </div>
  );
};

export default Restaurants;
