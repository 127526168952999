import React, { useState, useEffect, useRef } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navbar.css';
import logo from './images/logo.png';
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useMediaQuery} from "@mui/material";
import theme from "../theme";
import CookieNotice from "./CookieNotice";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [scroll, setScroll] = useState(0);
  const menuRef = useRef(null); // Crear una referencia para el menú
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  const [scrollValue, setScrollValue] = useState(375);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY);
    };

    const handleClickOutside = (event) => {
      // Verifica si el clic fue fuera del menú
      if (menuRef.current && !menuRef.current.contains(event.target) && click) {
        closeMenu();
      }
    };

    // Establecer el valor de scroll
    const viewportHeight = window.innerHeight;
    const scrollValue = viewportHeight * 0.45;
    setScrollValue(scrollValue);

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside); // Escuchar clics en el documento

    // Limpiar eventos al desmontar
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [click]); // Dependencia click

  return (
    <div className={`header ${scroll > scrollValue ? 'fixed' : ''}`}>
      <nav className='navbar'>
        {click && isLargeScreen && <ShoppingCartIcon className={"hidden"}/>}
        <a href='/shop' className={click ? 'shoppinCartNavbarAbsolute' : 'shoppinCartNavbar'}>
          <ShoppingCartIcon />
        </a>
        <a href='/' className='logo'>
          <img className="headertitle" src={logo} alt="MDN"/>
        </a>
        <div className='hamburger' onClick={handleClick}>
          {click ? <FaTimes size={30} style={{color: '#000000'}}/> : <FaBars size={30} style={{color: '#000000'}}/>}
        </div>
        <div ref={menuRef} className={click ? 'nav-menu active' : 'nav-menu'}>
          <ul className='menu-items'>
            <li className='nav-item'>
              <a href='/' onClick={closeMenu}>
                Inicio
              </a>
            </li>
            <li className='nav-item'>
              <a href='/wine' onClick={closeMenu}>
                Nuestro Vino
              </a>
            </li>
            <li className='nav-item'>
              <a href='/aboutUs' onClick={closeMenu}>
                Equipo
              </a>
            </li>
            <li className='nav-item'>
              <a href='/Shop' onClick={closeMenu}>
                Tienda Online
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <CookieNotice/>
    </div>
  );
};

export default Navbar;
