import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import "./ShippingCart.css"
import { useLocation } from 'react-router-dom';

const ShippingCart = ({ products, shippingCosts }) => {
  const rows = useLocation().state?.products.map((product) => ({
    name: product.name,
    quantity: product.quantity,
    price: product.price,
  }));

  // Calcular el total de los productos sin redondear hasta el final
  const totalProducts = rows.reduce(
    (acc, product) => acc + (product.price * product.quantity),
    0
  );
  const validShippingCost = shippingCosts?.price !== -1 ? shippingCosts?.price : 0;
  const totalFinal = totalProducts + validShippingCost;

  return (
    <TableContainer className={"cartTable"}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Artículos</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Precio unitario</TableCell>
            <TableCell align="right">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.quantity}</TableCell>
              <TableCell align="right">
                {row.price.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
              </TableCell>
              <TableCell align="right">
                {(row.price * row.quantity).toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €
              </TableCell>
            </TableRow>
          ))}

          <TableRow
            key={shippingCosts.text}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {shippingCosts.text} ({shippingCosts.province})
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">
              {shippingCosts.price > 0 ? `${shippingCosts.price.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €` : ''}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell component="th" scope="row">
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right"></TableCell>
            <TableCell align="right">
              <strong>{totalFinal.toLocaleString('es-ES', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €</strong>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ShippingCart;
