import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import AgePopUp from "../AgePopUp";

const useCheckAge = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAdult = document.cookie.split('; ').find(row => row.startsWith('isAdult='));
    if (!isAdult || isAdult.split('=')[1] !== 'true') {
      navigate('/');
    }
  }, [navigate]);
};

const CheckAgeMainPage = () => {
  const [above18, setabove18] = useState(true);

  useEffect(() => {
    const isAdult = document.cookie.split('; ').find(row => row.startsWith('isAdult='));
    if (!isAdult || isAdult.split('=')[1] !== 'true') {
      setabove18(false);
    }
  });

  return (
    <>
      {!above18 && <AgePopUp />}
    </>
  );
};

export { useCheckAge, CheckAgeMainPage };
