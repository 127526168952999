import React from 'react'
import './Title.css'
import logo from './images/logo.png'

const Hero = () => {
    return (
        <img className="title" src={logo} alt="MDN" />
    )
}

export default Hero
