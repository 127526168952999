import "./PrivacyPolicy.css"

const Fines = () => {
  return (
    <ul style={{ listStyleType: 'disc', }}>
      <li>
        Para fines de marketing y promoción.
        <ul style={{ listStyleType: 'circle' }}>
          <li>Para el envío de información de la Empresa, la base jurídica es el consentimiento que nos prestas, que puede ser retirado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</li>
          <li>Para la gestión de eventos, el tratamiento es necesario para la ejecución del servicio en el que la persona interesada es parte. Si no se aportan los datos necesarios no se podrá participar en el evento.</li>
        </ul>
      </li>
      <li>
       Gestionar la relación con clientes y potenciales clientes, o usuarios registrados en el Sitio Web.
        <ul style={{ listStyleType:  'circle' }}>
          <li>El tratamiento es necesario para la ejecución y desarrollo del contrato o para la prestación de un servicio en el que la persona interesada es parte o representante de una parte.</li>
          <li>Igualmente, la Empresa puede tener un interés legítimo en remitir información comercial y propuestas a clientes o potenciales clientes para la prestación de sus servicios o venta de sus productos.</li>
        </ul>
      </li>
      <li>
        Gestionar consultas y solicitudes.
        <ul style={{listStyleType:  'circle' }}>
            <li>Registrar y gestionar las consultas y solicitudes de información formuladas a la Empresa.</li>
        </ul>
      </li>
      <li>
        Gestionar la relación con proveedores - colaboradores
        <ul style={{listStyleType:  'circle' }}>
            <li>Gestión de la relación con proveedores (Administración, gestión de ingresos y gastos, formalización de servicios, posibles reclamaciones, etc.).</li>
        </ul>
      </li>
      <li>
        Atención a los derechos de las personas.
        <ul style={{listStyleType:  'circle' }}>
          <li>Atender las solicitudes de los interesados en el ejercicio de los derechos que establece el Reglamento General de Protección de Datos.</li>
        </ul>
      </li>
    </ul>
);
};

export default Fines;
