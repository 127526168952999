import React from 'react'
import arrow from './images/arrow.svg'
import './Arrow.css'

const Arrow = () => {
    return (
        <div className="container_arrow">
            <img className="arrow" src={arrow} alt="MDN" />
        </div>
    )
}

export default Arrow
