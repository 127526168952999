import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import founder1 from './images/macrino.jpg'; // Importar las imágenes
import founder2 from './images/alfonso.jpg';
import founderTogether from './images/founders.jpg';
import vision from './images/catando.jpg';
import NavbarB from "./NavbarB";
import headerIMG from "./images/equipoHeader.jpg";
import Footer from "./footer/Footer";
import Bottle from "./Bottle";
import "./AboutUs.css"

const FounderImage = styled('img')(({ theme }) => ({
  height: 'auto',
  borderRadius: '8px',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(212, 175, 55, 0.6)', // Efecto dorado en hover
  },
  [theme.breakpoints.up('xs')]: {
    width: '90vw', // Cambiar a 30vw en pantallas medianas y más grandes
    left:0,
  },
  [theme.breakpoints.up('md')]: {
    width: '40vw', // Cambiar a 25vw en pantallas grandes y más grandes
  },
}));

const AboutUs = () => {
  return (
    <Box sx={{ backgroundColor: '#5c5c5c', padding: 0, minHeight: '100vh' }}>
    {/* Primera Sección: Título Grande con Fondo */}
      <Box
        sx={{
          background: `url(${headerIMG}) center/cover no-repeat`,
          padding: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: '#7c1d12',
            fontFamily: "'Marcellus', serif",
            
            textShadow: '2px 2px 8px rgba(0, 0, 0, 0.7)',
            letterSpacing: '4px',
          }}
        >
          Equipo
        </Typography>
      </Box>
      <Box sx={{ backgroundColor: '#5c5c5c', padding: 4, minHeight: '100vh' }}>
          {/* Sección de fundadores */}
          <Grid container spacing={6} sx={{ marginBottom: 6 }}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#5c5c5c',
                  padding: {lg: 4, xs:0},
                  borderRadius: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontFamily: "'Marcellus', serif", color: '#7c1d12', marginBottom: 2 }}>
                  Victor García
                </Typography>
                <Typography variant="h6" sx={{ color: 'white', fontFamily: "'Marcellus', serif", marginBottom: 3 }}>
                    Con una trayectoria destacada en el mundo de la enología, Víctor ha trabajado en diversas bodegas, acumulando una vasta experiencia y un profundo conocimiento del arte de hacer vino. Apasionado por cada etapa del proceso, desde la viña hasta la copa, Víctor pone en cada botella no solo su experiencia, sino también su alma, buscando siempre la excelencia y la autenticidad.
                </Typography>
                <FounderImage src={founder1} alt="Imagen del Fundador 1" />
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  backgroundColor: '#5c5c5c',
                  padding: {lg: 4, xs:0},
                  borderRadius: 2,
                }}
              >
                <Typography variant="h4" sx={{ fontFamily: "'Marcellus', serif", color: '#7c1d12', marginBottom: 2 }}>
                  Alfonso Pelayo
                </Typography>
                <Typography variant="h6" sx={{ color: 'white', fontFamily: "'Marcellus', serif", marginBottom: 3 }}>
                    Economista de carrera y consultor financiero de profesión, su pasión por el vino le llevó a unirse a esta aventura enológica, aportando al proyecto una visión estratégica y una sólida base financiera, compartiendo no solo el amor por el vino, sino también la dedicación y el compromiso con la calidad. Su enfoque meticuloso y su habilidad para manejar los aspectos financieros son fundamentales para el éxito del proyecto.
                </Typography>
                <FounderImage src={founder2} alt="Imagen del Fundador 2" />
              </Box>
            </Grid>
          </Grid>

          {/* Sección con imagen de ambos fundadores */}
          <Grid container spacing={4} alignItems="center" sx={{ marginBottom: 6 }}>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "'Marcellus', serif", color: '#7c1d12', textAlign: 'left', marginBottom: 2 }}
              >
                Nuestra Historia
              </Typography>
              <Typography variant="h6" class="paragraphAbout" sx={{ color: 'white', fontFamily: "'Marcellus', serif", lineHeight: 1.8 }}>
                <p>Nuestro sueño siempre fue crear nuestro propio vino, y encontramos en Tórtoles de Esgueva, una zona desconocida de la Ribera del Duero, el lugar perfecto para hacerlo realidad.</p>
                <p>En Tórtoles de Esgueva, hemos unido nuestras fuerzas y nuestras pasiones para crear un proyecto único, donde cada botella refleja nuestro esfuerzo conjunto, nuestra amistad y nuestro amor por el buen vino.</p>
                <p>Compartimos una misión común: crear vinos de la más alta calidad que no solo reflejen el carácter de nuestra tierra, sino también la pasión y dedicación que ponemos en cada botella.</p>
                <p>Nos enfocamos en mantener viva la tradición vitivinícola, pero con un enfoque fresco e innovador, con el objetivo de llevar el vino a las nuevas generaciones, conservando el respeto por los métodos antiguos, pero sin miedo a probar cosas nuevas, siempre respetando nuestra tradición.</p>
                <p>Queremos que cada persona que pruebe nuestros vinos sienta la dedicación y el cuidado que ponemos en cada detalle.</p>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <FounderImage src={founderTogether} alt="Fundadores juntos" />
            </Grid>
          </Grid>

          {/* Sección con la visión */}
          <Grid container spacing={4} alignItems="center"
            sx={{
                gap: {lg: "0"},
                marginBottom: {lg: "10vw"},
                flexDirection: {xs: "column-reverse", lg: "row"},
                flexWrap: {xs: "column-reverse", lg: "wrap"}
            }}
          >
            <Grid item xs={12} md={6}>
              <FounderImage src={vision} alt="Nuestra Misión" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h4"
                sx={{ fontFamily: "'Marcellus', serif", color: '#7c1d12', textAlign: 'left', marginBottom: 2 }}
              >
                Nuestra Misión
              </Typography>
              <Typography variant="h6" sx={{ color: 'white', fontFamily: "'Marcellus', serif", lineHeight: 1.8 }}>
                Nos enfocamos en mantener viva la tradición vitivinícola, pero con un enfoque fresco e innovador.
                Nuestra misión es llevar el vino a nuevas generaciones, conservando el respeto por los métodos antiguos
                pero sin miedo a probar cosas nuevas, siempre respetando nuestra tierra.
              </Typography>
            </Grid>
          </Grid>
        </Box>
          <Bottle />
          <NavbarB/>
          <Footer />
      </Box>
  );
};

export default AboutUs;
